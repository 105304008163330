<template>
    <div class="main" :style="{ backgroundImage: `url(${playerBag})` }">
        <!-- 导航 -->
        <page-head title="视频" />
        <div class="content">
            <template v-if="videoData.length > 0">
                <div class="list">
                    <div v-for="(item, index) in videoData" :key="item.id" class="item" @click="details(item)">
                        <!-- 序号 -->
                        <index-number
                            class="number"
                            spanColor="rgba(255, 255, 255, 0.3)"
                            iColor="rgba(255, 255, 255, 0.8)"
                        >
                            <template v-slot:number>
                                <span style="color: #602B89;" class="akrobatBold">{{ index + 1 }}</span>
                            </template>
                        </index-number>
                        <!-- 封面 -->
                        <img class="img" :src="item.snapshot ? item.snapshot : require('@i/common/exerciseBg.png')" alt="">
                        <!-- 视频名 -->
                        <div class="item_box">
                            <p class="item_name">
                                {{ item.name }}
                            </p>
                            <p class="item_date akrobatRegular">
                                {{ $moment(new Date(item.createTime)).format('YYYY/MM/DD HH:mm:ss') }}
                            </p>
                        </div>
                    </div>
                </div>
                <!-- 翻页 -->
                <pagination-com
                    class="pagination"
                    :current-page="query.page"
                    :total-page="totalPage"
                    :style-num="{color: '#fff', opacity: '0.4'}"
                    @changePage="changePage"
                />
            </template>
            <no-data v-else />
        </div>
    </div>
</template>

<script>
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import { defineComponent, onMounted, getCurrentInstance, toRefs, reactive, watch, computed } from 'vue';
import { useStore } from "vuex";
export default defineComponent({
    setup() {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const { proxy } = getCurrentInstance();
        const { playerBag } = proxy.$Config.UI_CONFIG
        const { id } = store.state.teamData;
        const state = reactive({
            playerBag,
            query: {
                paging: true,
                pageSize: 10,
                page: 1,
            },
            videoData: [],
            totalPage: '',
            loading: true,
        });

        watch(() => store.state.identifyData, (val, old) => {
            console.log(val, old);
            if (!val.type) return false;
            const params = {
                page: state.query.page,
                totalPage: state.totalPage,
                showList: state.videoData,
                ...val,
            }
            proxy.$utils.identifyFn({
                type: val.type,
                data: params,
                changePage: changePage,
                router: details
            })
        })

        const getData = async () => {
            state.loading = true;
            if (route.query.type === 'team') {
                state.query['teamId'] = id
            }else if (route.query.type === 'camp') {
                state.query['teensCampId'] = id
            }
            const {
                code,
                data: { list, pages }
            } = await (route.query.type === 'team' ?  proxy.$server.getVideoList(state.query) : proxy.$server.getCampVideos(state.query));
            state.loading = false;
            if (code === 200) {
                state.videoData = list;
                state.totalPage = pages;
            }
        }

        const changePage = (page) => {
            state.query.page = page
            getData()
        }

        const details = (row) => {
            router.push({
                path: '/videoList/clipsList',
                query: {
                    id: row.id,
                    name: row.name,
                    pageType: 'video'
                }
            })
        }

        const queryParams = computed(() => {
            return store.state.queryParams;
        });

        onMounted(() => {
            if(queryParams.value.page) {
                state.query.page = queryParams.value.page
            }
            getData()
        })

        onBeforeRouteLeave((to, from, next) => {
            console.log(to)
            let params = {
                page: state.query.page
            }
            if(to.name === 'videoClipsList') {
                store.commit('setQueryParams', params);
            } else {
                params.page = 1
                store.commit('setQueryParams', params);
            }
            next()
        })
        
        return {
            changePage,
            details,
            ...toRefs(state),
        }
    },
})
</script>

<style scoped lang="scss">
.main {
    width: 100%;
    height: 100%;
    background-size: 100% auto;
    position: relative;
    
    .content {
        position: relative;
        margin: 24px 80px 0;
        background: linear-gradient(110.99deg, rgba(148, 80, 236, 0.2) 40.35%, rgba(148, 80, 236, 0.2) 86.39%);
        box-shadow: 0px 40px 80px rgba(63, 25, 115, 0.1);
        backdrop-filter: blur(15px);
        box-sizing: border-box;
        height: calc(100% - 112px);
        border-radius: 16px 16px 0 0;
        .list {
            display: grid;
            grid-template-columns: repeat(5, 314px);
            grid-template-rows: auto;
            justify-content: space-between;
            padding: 32px 30px 0;
            height: 760px;
            box-sizing: border-box;
            .item {
                position: relative;
                width: 100%;
                height: 300px;
                cursor: pointer;
                overflow: hidden;

                .number {
                    position: absolute;
                    top: 13px;
                    left: 13px;
                }

                .img {
                    width: 100%;
                    height: 186px;
                    border-radius: 6px;
                }

                .item_box {
                    margin-top: 18px;
                    color: #fff;
                    font-size: 24px;
                    .item_name {
                        word-break: break-all;
                        width: 100%;
                        min-height: 68px;
                        line-height: 34px;
                        opacity: 0.8;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                    .item_date {
                        opacity: 0.6;
                    }
                }
            }
        }
        .pagination {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
</style>